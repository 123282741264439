import { safelyGetImageDimensions } from "./safelyGetImageDimensions";
import SanityImage from "./SanityImage";

export const SanityImageFillAvailableSpace = (props: Omit<React.ComponentProps<typeof SanityImage>, "aspectRatio">) => {
  const sourceDimension = safelyGetImageDimensions(props.image);

  return (
    <SanityImage
      chakraProps={{
        width: "100%",
      }}
      imageWrapperProps={{ position: "relative", overflow: "hidden" }}
      aspectRatio="fill"
      {...props}
      resolution={Math.min(sourceDimension.width, props.resolution)} // For å ikke blåse opp bilder som uansett er små
    />
  );
};
