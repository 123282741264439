import { Button, Flex, LinkBox, LinkBoxProps, LinkOverlay, Stack } from "@biblioteksentralen/react";
import { useId } from "react";
import { getPath } from "../../utils/getPath";
import { useTranslation } from "../../utils/hooks/useTranslation";
import CreatedByTag from "../employees/CreatedByTag";
import { RecommendationHero } from "./RecommendationHero";
import { ResolvedRecommendation, ResolvedRecommendationSummary } from "./sanityQuery";
import { Teaser } from "./Teaser";
import { LocalizedNextLink } from "../LocalizedNextLink";

type Props = {
  recommendation: ResolvedRecommendationSummary | ResolvedRecommendation;
} & LinkBoxProps;

const RecommendationCardLarge = ({ recommendation, ...chakraProps }: Props) => {
  const { t } = useTranslation();
  const headingId = useId();

  if (!recommendation.publication) return null;

  return (
    <LinkBox aria-labelledby={headingId} aria-description={t("anbefaling")} {...chakraProps}>
      <RecommendationHero
        recommendation={recommendation}
        marginTop={{ base: "2rem", md: "1rem" }}
        headingId={headingId}
      >
        <Stack spacing="1rem">
          <Teaser quoteMarkProps={{ color: "white" }} recommendation={recommendation} />
          <CreatedByTag createdBy={recommendation.createdBy} />
          <Flex>
            <LocalizedNextLink href={getPath(recommendation)} passHref legacyBehavior>
              <LinkOverlay _hover={{ backgroundColor: "hsla(0deg, 0%, 50%, 0.15)" }}>
                <Button as="span" variant="secondary" color="inherit">
                  {t("Se anbefalingen")}
                </Button>
              </LinkOverlay>
            </LocalizedNextLink>
          </Flex>
        </Stack>
      </RecommendationHero>
    </LinkBox>
  );
};

export default RecommendationCardLarge;
