import { CSSProperties } from "react";

/* Vi bruker samme breakpoints som Chakra, gir forutsigbarhet */
const breakpoints: { [key: string]: string } = {
  base: "0em",
  sm: "30em",
  md: "48em",
  lg: "62em",
  xl: "80em",
} as const;

type Breakpoints = keyof typeof breakpoints;

type ContainerQuery = {
  [key in Breakpoints]?: CSSProperties;
};

export const getContainerQuery = (breakpoint: ContainerQuery) => {
  const containerQuery = Object.entries(breakpoint)
    .map(([breakpoint, styles]) => {
      const container = [`@container (min-width: ${breakpoints[breakpoint]})`];
      return { container: [...container][0], styles };
    })
    .flat();

  return containerQuery.reduce((acc, curr) => {
    if (typeof curr.container !== "undefined") {
      acc[curr.container] = curr.styles;
    }
    return acc;
  }, {} as ContainerQuery);
};
