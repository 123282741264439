import { randomFromArray, seededRandom } from "@biblioteksentralen/utils";
import { zip } from "radash";
import { ResolvedPaletteColor, useSitePalette } from "../../utils/useSitePalette";
import { useCommonData } from "../layout/CommonDataProvider";
import { ResolvedListSummary } from "./sanityQuery";

// Source: https://stackoverflow.com/a/46545530/1540547
const shuffleColorsByIds = (colorsAndIds: [ResolvedPaletteColor, string][]): ResolvedPaletteColor[] =>
  colorsAndIds
    .slice()
    .map(([color, id]) => ({ color, sort: seededRandom(id) }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ color }) => color);

const twoArrayElementsDefined = (
  elements: [ResolvedPaletteColor?, string?]
): elements is [ResolvedPaletteColor, string] =>
  typeof elements[0] !== "undefined" && typeof elements[1] !== "undefined";

/**
 * Random color not repeated until all colors are exhausted, then repeated from start.
 * Color assigned to an individual book list will change when creating / deleting lists.
 */
export const getListColorMap = (
  ids: string[] = [],
  colors: ResolvedPaletteColor[]
): Record<string, ResolvedPaletteColor> => {
  const colorsAndIds = zip(colors, ids).filter(twoArrayElementsDefined);
  const colorsShuffledByIds = shuffleColorsByIds(colorsAndIds);
  const nColors = colorsShuffledByIds.length;

  return Object.fromEntries(ids.map((id, index) => [id, colorsShuffledByIds[index % nColors]!]));
};

export const useListColor = (list?: ResolvedListSummary): ResolvedPaletteColor => {
  const palette = useSitePalette();
  const colors = [
    palette.colors.lightaccent1,
    palette.colors.lightaccent3,
    palette.colors.darkaccent3,
    palette.colors.lightaccent2,
    palette.colors.lightaccent4,
    palette.colors.pop2,
  ];
  const bookListIds = useCommonData().bookListStyling?.bookListIds;
  if (!list) return randomFromArray(colors);
  return getListColorMap(bookListIds, colors)[list._id] ?? randomFromArray(colors); // random fallback dersom sitecontext med bookListIds ikke finnes, bla på /dev/landingpage;
};
