import { BookIcon } from "@biblioteksentralen/icons";
import { Box, BoxProps, Grid, ResponsiveValue } from "@biblioteksentralen/react";
import { isPublicationWithImage } from "@libry-content/common";
import { Publication } from "@libry-content/types";
import { useSitePalette } from "../../utils/useSitePalette";
import { CoverImage } from "./CoverImage";
import { FavoriteButtonWrapper } from "../../searchAndCollection/components/favorites/FavoriteButton";

interface Props {
  publication: Publication;
  resolution?: number;
  imageWidth?: ResponsiveValue<string>;
  showFavoriteButton?: boolean;
}

const transformStyles = {
  transition: ".9s",
  _groupHover: { transition: ".5s", transform: "scale(1.05)" },
};

const CoverImageWithPlaceholder = ({
  publication,
  resolution,
  imageWidth,
  showFavoriteButton,
  ...boxProps
}: Props & BoxProps) => {
  const palette = useSitePalette();

  return (
    <Box {...boxProps}>
      {isPublicationWithImage(publication) ? (
        <Grid gridTemplateRows="1fr 4rem" gridTemplateColumns="4rem 1fr">
          <CoverImage
            borderRadius=".5rem"
            publication={publication}
            resolution={resolution ?? 250}
            maxWidth={imageWidth}
            gridColumn="1/3"
            gridRow="1/3"
            {...transformStyles}
          />
          <Box gridColumn="1/2" gridRow="2/3" alignSelf="center" justifySelf="center">
            {publication.workId && showFavoriteButton && (
              <FavoriteButtonWrapper workOrWorkId={publication.workId} position="relative" />
            )}
          </Box>
        </Grid>
      ) : (
        <Grid gridTemplateRows="1fr 4rem" gridTemplateColumns="4rem 1fr">
          <Box gridColumn="1/2" gridRow="2/3" alignSelf="center" justifySelf="center">
            {publication.workId && showFavoriteButton && (
              <FavoriteButtonWrapper workOrWorkId={publication.workId} position="relative" />
            )}
          </Box>
          <Grid
            css="aspect-ratio: .7;"
            borderRadius=".5rem"
            placeItems="center"
            width={imageWidth}
            aria-hidden
            gridColumn="1/3"
            gridRow="1/3"
            {...palette.colors.darkaccent4.css}
            {...transformStyles}
          >
            <BookIcon fontSize="2em" />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default CoverImageWithPlaceholder;
