import { ComponentType, forwardRef, ForwardRefExoticComponent } from "react";
import ErrorBoundary from "./ErrorBoundary";

export const withErrorBoundary = <Props,>(
  Component: ForwardRefExoticComponent<Props> | ComponentType<Props>,
  boundaryName: string
) => {
  const ComponentWithErrorBoundary = forwardRef((props: Props, ref) => (
    <ErrorBoundary boundaryName={boundaryName}>
      <Component {...props} ref={ref} />
    </ErrorBoundary>
  ));

  ComponentWithErrorBoundary.displayName = Component.displayName;

  return ComponentWithErrorBoundary;
};
