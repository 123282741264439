import {
  Box,
  BoxProps,
  Button,
  colors,
  Flex,
  Grid,
  Heading,
  HideWithCSS,
  LinkBox,
  LinkOverlay,
  Stack,
  Text,
} from "@biblioteksentralen/react";
import { EditableDocument } from "@libry-content/common";
import { Promotion } from "@libry-content/types";
import { ComponentProps, ReactNode, useId } from "react";
import { useTranslation } from "../../../utils/hooks/useTranslation";
import { ResolvedPaletteColor } from "../../../utils/useSitePalette";
import { Edit } from "../../editInSanity/EditInSanity";
import SanityImage from "../../images/SanityImage";
import { SanityImageSrc } from "../../images/SanityImageCore";
import { SanityImageFillAvailableSpace } from "../../images/SanityImageFillAvailableSpace";
import { LocalizedNextLink } from "../../LocalizedNextLink";
import { getContainerQuery } from "./promotedItems/utils";

type Props = BoxProps & {
  bannerColor?: ResolvedPaletteColor;
  sanityImageSrc?: SanityImageSrc;
  customImageComponent?: ReactNode;
  extraInfo?: ReactNode;
  imageSide?: "right" | "left";
  editDocumentProps: EditableDocument;
  seeMore: {
    href: string;
    label: string;
    ariaLabel?: string;
  };
  title?: string;
  teaser?: string;
  tagline?: ReactNode;
  imageWidthInPercent?: number;
  promotion?: Promotion;
};

export const SingleItemBanner = (props: Props) => {
  const {
    bannerColor,
    editDocumentProps,
    sanityImageSrc,
    customImageComponent,
    extraInfo,
    imageSide,
    seeMore,
    title,
    teaser,
    tagline,
    imageWidthInPercent = 50,
    promotion,
    ...boxProps
  } = props;
  const headingId = useId();
  const { ts } = useTranslation();
  const imageOnRightSide = imageSide === "right";
  const promotionalText = ts(promotion?.advanced?.promotionalText);
  return (
    <Grid
      sx={{
        containerType: "inline-size",
      }}
      {...boxProps}
    >
      <LinkBox>
        <Flex
          aria-labelledby={headingId}
          background={colors.grey10}
          {...bannerColor?.css}
          minHeight="24rem"
          sx={getContainerQuery({
            base: { flexDirection: "column" },
            sm: { flexDirection: imageOnRightSide ? "row-reverse" : "row", justifyContent: "space-between" },
          })}
          height="100%"
          borderRadius={{ base: "0", md: ".5rem" }}
          overflow="hidden"
        >
          <Box flex={`0 1 ${imageWidthInPercent}%`}>
            {customImageComponent || <SingleItemBannerImage image={sanityImageSrc} borderRadius="0" />}
          </Box>
          <Flex direction="column" flex="1 0" justifyContent="space-around">
            <Flex
              direction="column"
              minHeight="80%"
              alignItems="flex-start"
              justifyContent="space-around"
              sx={getContainerQuery({
                base: { padding: "1rem", height: "100%", justifyContent: "space-between", gap: "1rem" },
                sm: { padding: "2rem", height: "inherit", justifyContent: "inherit" },
                md: { padding: "2rem 2rem 2rem 3rem" },
              })}
            >
              <Stack sx={getContainerQuery({ base: { gap: ".1rem" }, md: { gap: ".5rem" } })}>
                <Box fontSize=".8rem" fontWeight="600">
                  {tagline}
                </Box>
                <Heading
                  as="h3"
                  id={headingId}
                  size="md"
                  sx={getContainerQuery({
                    sm: { fontSize: "1.5rem" },
                    md: { fontSize: "2rem" },
                  })}
                >
                  {promotionalText || title}
                </Heading>
                <Text fontSize=".9rem" noOfLines={2}>
                  {!promotionalText && teaser}
                </Text>
                {extraInfo && <Box marginTop=".75rem">{extraInfo}</Box>}
              </Stack>
              <LocalizedNextLink href={seeMore.href} passHref legacyBehavior>
                <LinkOverlay
                  _hover={{ backgroundColor: "hsla(0deg, 0%, 50%, 0.15)" }}
                  aria-label={seeMore.ariaLabel}
                  sx={getContainerQuery({ base: { alignSelf: "center" }, sm: { alignSelf: "inherit" } })}
                >
                  <Button as="span" variant="secondary" color="inherit">
                    {seeMore.label}
                  </Button>
                </LinkOverlay>
              </LocalizedNextLink>
              <Edit doc={editDocumentProps} />
            </Flex>
          </Flex>
        </Flex>
      </LinkBox>
    </Grid>
  );
};

type SingleItemBannerImageProps = { image: ComponentProps<typeof SanityImage>["image"]; borderRadius?: string };

export const SingleItemBannerImage = ({ image, borderRadius }: SingleItemBannerImageProps) => (
  <>
    <HideWithCSS above="sm">
      <SanityImage image={image} aspectRatio={8 / 5} resolution={800} borderRadius={borderRadius} hideCaption />
    </HideWithCSS>
    <HideWithCSS below="sm" height="100%" width="100%">
      <SanityImageFillAvailableSpace image={image} resolution={1080} borderRadius={borderRadius} hideCaption />
    </HideWithCSS>
  </>
);

export default SingleItemBanner;
