import { BoxProps, Flex, Icon, IconProps, Text } from "@biblioteksentralen/react";
import { Recommendation } from "@libry-content/types";
import { useTranslation } from "../../utils/hooks/useTranslation";
import { useSitePalette } from "../../utils/useSitePalette";
import QuoteIcon from "./QuoteIcon";

type Props = {
  recommendation: Pick<Recommendation, "teaser">;
  quoteMarkProps?: IconProps;
} & BoxProps;

export const Teaser = ({ recommendation, quoteMarkProps, noOfLines, ...chakraProps }: Props) => {
  const { ts, populateLang } = useTranslation();
  const palette = useSitePalette();

  return (
    <Flex isolation="isolate" alignItems="baseline" {...chakraProps}>
      <Icon
        as={QuoteIcon}
        fontSize="3em"
        color={palette.colors.card.css.background}
        aria-hidden={true}
        {...quoteMarkProps}
      />
      <Text
        {...populateLang(recommendation.teaser)}
        marginLeft="-.6em"
        transform="translateY(-.25em)"
        maxW="20em"
        fontStyle="italic"
        fontSize="1.25em"
        noOfLines={noOfLines}
      >
        {ts(recommendation.teaser)}
      </Text>
    </Flex>
  );
};
