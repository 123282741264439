import { BookIcon } from "@biblioteksentralen/icons";
import { Box, BoxProps, ChakraProps, colors, Icon, Image, Skeleton } from "@biblioteksentralen/react";
import { useState } from "react";

interface CoverImageProps extends ChakraProps {
  src?: string;
}

const FallBack = (chakraProps: BoxProps) => (
  <Box
    sx={{ aspectRatio: "0.7" }}
    borderRadius="lg"
    backgroundColor={colors.accentBlueMedium}
    display="flex"
    justifyContent="center"
    alignItems="center"
    color="white"
    width="100%"
    padding="30%"
    {...chakraProps}
  >
    <Icon as={BookIcon} aria-hidden width="100%" height="auto" />
  </Box>
);

export const CoverImage = ({ src, ...chakraProps }: CoverImageProps) => {
  const [hasError, setHasError] = useState(false);

  if (!src) return <FallBack {...chakraProps} />;

  const handleError = () => {
    console.warn(`Failed to load cover image from ${src}`);
    setHasError(true);
  };

  return (
    <Image
      fallback={
        hasError ? (
          <FallBack />
        ) : (
          <Skeleton sx={{ aspectRatio: "0.7" }} speed={1.5} borderRadius="lg" {...chakraProps} />
        )
      }
      onError={handleError}
      borderRadius="lg"
      src={src}
      alt=""
      {...chakraProps}
    />
  );
};
