import { HStack } from "@biblioteksentralen/react";
import {
  DocumentWithTargetAudiences,
  targetAudienceCodeIndex,
  targetAudiences as targetAudienceLabels,
} from "@libry-content/common";
import { sort } from "radash";
import { useTranslation } from "../../utils/hooks/useTranslation";
import Badge from "../Badge";

type TargetAudiencesProps<T extends DocumentWithTargetAudiences> = {
  targetAudiences: T["targetAudiences"];
};

const TargetAudiences = <T extends DocumentWithTargetAudiences>({ targetAudiences }: TargetAudiencesProps<T>) => {
  const { ts } = useTranslation();

  if (!targetAudiences?.length) return null;

  return (
    <Badge>
      <HStack gap=".5em" divider={<>•</>}>
        {sort(targetAudiences, targetAudienceCodeIndex).map((code, i) => (
          <span key={i}>{ts(targetAudienceLabels[code])}</span>
        ))}
      </HStack>
    </Badge>
  );
};

export default TargetAudiences;
