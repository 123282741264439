import { Badge, Box, BoxProps, Flex, FlexProps } from "@biblioteksentralen/react";
import { getFirstThreePublicationsWithImage } from "@libry-content/common";
import { getPath } from "../../utils/getPath";
import { useTranslation } from "../../utils/hooks/useTranslation";
import CreatedByTag from "../employees/CreatedByTag";
import { withErrorBoundary } from "../errorPages/withErrorBoundary";
import SingleItemBanner from "../site/banners/SingleItemBanner";
import { CoverImage } from "./CoverImage";
import { BookListCoversFan } from "./CoversFan";
import TargetAudiences from "./TargetAudiences";
import { ResolvedListSummary } from "./sanityQuery";
import { useListColor } from "./utils";

interface Props extends BoxProps {
  list: ResolvedListSummary;
  tagline?: string;
}

const BookListPreviewLarge = ({ list, tagline, ...boxProps }: Props) => {
  const { t, ts } = useTranslation();
  const paletteColor = useListColor(list);

  const imagesComponent = (
    <Flex
      role="group"
      placeItems="center"
      height="100%"
      padding={{ base: "2rem .5rem .5rem", sm: "1rem 0 1rem 1rem", md: "2rem 0 2rem 3rem", lg: "2rem" }}
    >
      <Box display={{ base: "none", lg: "block" }} flexGrow={1}>
        <ThreeCoversSideBySide list={list} />
      </Box>
      <Flex display={{ base: "flex", lg: "none" }} flexGrow={1} justifyContent="center">
        <BookListCoversFan publications={list.publications} maxWidth="22rem" />
      </Flex>
    </Flex>
  );

  return (
    <SingleItemBanner
      bannerColor={paletteColor}
      customImageComponent={imagesComponent}
      imageWidthInPercent={60}
      seeMore={{ href: getPath(list), label: t("Se liste") }}
      editDocumentProps={list}
      aria-description={t("leseforslag")}
      promotion={list.promotion}
      tagline={
        list.targetAudiences?.length ? (
          <TargetAudiences targetAudiences={list.targetAudiences} />
        ) : (
          <Badge>{tagline}</Badge>
        )
      }
      title={ts(list.title)}
      teaser={ts(list.teaser)}
      extraInfo={<CreatedByTag createdBy={list.createdBy} />}
      {...boxProps}
    />
  );
};

export function ThreeCoversSideBySide({ list, ...flexProps }: Pick<Props, "list"> & FlexProps) {
  return (
    <Flex
      direction="row"
      gridGap=".75rem"
      justifyContent="center"
      alignItems="flex-end"
      alignSelf="center"
      flexWrap="wrap"
      {...flexProps}
    >
      {getFirstThreePublicationsWithImage(list.publications).map((publication) => (
        <CoverImage
          key={publication.image?.asset?._ref}
          maxWidth="max(30%, 6rem)"
          publication={publication}
          resolution={300}
        />
      ))}
    </Flex>
  );
}

export default withErrorBoundary(BookListPreviewLarge, "BookListPreviewLarge");
