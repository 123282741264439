import { formatNameInformal } from "@libry-content/common";
import { ForrigeBokWork } from "@libry-content/integrations";
import { PublicationSummary } from "../components/lists/sanityQuery";

// TODO: Decide whether to format author names informally

/**
 * According to WCAG specifications, one should in general avoid images with text – but we need to
 * display cover images.
 *
 * In most contexts, the typical relevant information (title and author) will be repeated nearby
 * the image, but not always. One option would be to include title and author *if* it's not repeated
 * nearby, but this can lead to less deterministic semantic structure – i.e. a list or grid where
 * some images have alt text, and thus are present for screen readers, and some don't.
 *
 * Therefore we always include title and author in cover image alt texts.
 */
export const coverImageAltText = (
  publicationData: PublicationSummary | ForrigeBokWork["simplifiedPresentationMetadata"]
): string => {
  const author = isPublicationSummary(publicationData)
    ? formatNameInformal(publicationData.author ?? "")
    : publicationData.authors?.map(formatNameInformal).join(", ");

  if (publicationData.title && author) return `Omslag: "${publicationData.title}" av ${author}`;
  else if (publicationData.title) return `Omslag: "${publicationData.title}"`;
  else if (author) return `Omslag: ${author}`;

  console.error("Trying to generate cover image alt text without title or author");
  return "Omslag";
};

const isPublicationSummary = (item: unknown): item is PublicationSummary =>
  !!item && typeof item === "object" && (item as any)?.["_type"] === "publication";
