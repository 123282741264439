import { Avatar, AvatarProps, Box, ChakraProps } from "@biblioteksentralen/react";
import { User } from "react-feather";
import { useTranslation } from "../../utils/hooks/useTranslation";
import { useSitePalette } from "../../utils/useSitePalette";
import SanityImage from "../images/SanityImage";
import { ResolvedEmployee } from "./sanityQuery";

type Props = {
  employee: ResolvedEmployee | undefined;
  size: ChakraProps["width"];
  resolution?: number;
  fontSize?: AvatarProps["size"];
  hideCaption?: boolean;
};

const EmployeeAvatar = ({ employee, resolution, size, fontSize = "md", hideCaption = false }: Props) => {
  const { t } = useTranslation();
  const paletteColor = useSitePalette().colors.darkaccent4;

  if (!employee?.image?.asset || !resolution) {
    return (
      <Avatar
        name={employee?.name}
        {...paletteColor.css}
        fontWeight="600"
        width={size}
        height={size}
        size={fontSize}
        icon={<User width="1.25rem" height="1.25rem" />}
      />
    );
  }

  return (
    <Box width={size} height={size}>
      <SanityImage
        image={employee?.image}
        customNextImageProps={{
          alt: t("Profilbilde av {ansattNavn}", { ansattNavn: employee.name }),
        }}
        aspectRatio={1 / 1}
        resolution={resolution}
        borderRadius="full"
        hideCaption={hideCaption}
      />
    </Box>
  );
};

export default EmployeeAvatar;
