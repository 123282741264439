import { PublicationWithImage } from "@libry-content/common";
import { ComponentProps } from "react";
import { coverImageAltText } from "../../utils/coverImageAltText";
import { SanityImagePreservingAspectRatio } from "../images/SanityImagePreservingAspectRatio";

type CoverImageProps = Omit<ComponentProps<typeof SanityImagePreservingAspectRatio>, "image"> & {
  publication: PublicationWithImage;
};

export const CoverImage = ({ publication, publication: { image }, alt = "", ...rest }: CoverImageProps) => (
  <SanityImagePreservingAspectRatio
    image={image}
    alt={alt || coverImageAltText(publication) || ""}
    hideCaption
    {...rest}
  />
);
