import { Box } from "@biblioteksentralen/react";
import { ReactNode } from "react";

type Props = { children: ReactNode };

const Badge = ({ children }: Props) => (
  <Box fontSize="0.8rem" fontWeight="600" minHeight="0.8rem">
    {children}
  </Box>
);

export default Badge;
