import { Box, Flex, Grid, Heading, LinkBox, Stack, Text, colors } from "@biblioteksentralen/react";
import { useId } from "react";
import { getPath } from "../../utils/getPath";
import { usePublicationLangAttribute } from "../../utils/hooks/usePublicationLangAttribute";
import { useSitePalette } from "../../utils/useSitePalette";
import InternalLinkOverlay from "../InternalLinkOverlay";
import { Edit } from "../editInSanity/EditInSanity";
import CreatedByTag from "../employees/CreatedByTag";
import CoverImageWithPlaceholder from "../lists/CoverImageWithPlaceholder";
import TargetAudiences from "../lists/TargetAudiences";
import { Teaser } from "./Teaser";
import { ResolvedRecommendation, ResolvedRecommendationSummary } from "./sanityQuery";

interface Props {
  recommendation: ResolvedRecommendationSummary | ResolvedRecommendation;
  headingSize?: "h2" | "h3";
}

const RecommendationCard = ({ recommendation, headingSize = "h3" }: Props) => {
  const publicationLangAttribute = usePublicationLangAttribute(recommendation.publication);
  const headingId = useId();
  const palette = useSitePalette();

  if (!recommendation.publication) return null;

  const { publication, targetAudiences } = recommendation;
  const [title] = recommendation.publication.title?.split(":") ?? [];

  return (
    <Stack isolation="isolate" aria-labelledby={headingId}>
      <LinkBox>
        <Grid
          gridTemplateColumns={{ base: ".9fr 1fr" }}
          gridTemplateRows={{ base: "4rem 9rem 1fr", lg: "5rem 9rem 1fr" }}
          role="group"
        >
          <Grid gridColumn="1/2" gridRow="1/3" justifyContent="center" alignItems="center" paddingLeft="1rem">
            <CoverImageWithPlaceholder publication={publication} imageWidth="7rem" minWidth="3rem" />
          </Grid>
          <Teaser
            gridColumn="2/3"
            gridRow="2/3"
            alignSelf="center"
            padding=".5rem .75rem .5rem .25rem"
            fontSize="xs"
            noOfLines={4}
            recommendation={recommendation}
            quoteMarkProps={{ color: colors.white, marginRight: "-.25em" }}
            color={colors.black}
          />
          <Flex gridColumn="1/3" gridRow="3/4" flexDir="column" gap="0.2rem" paddingTop=".5rem">
            <TargetAudiences targetAudiences={targetAudiences} />
            <InternalLinkOverlay href={getPath(recommendation)} _groupHover={{ textDecoration: "underline" }}>
              <Heading as={headingSize} size="md" noOfLines={2} lang={publicationLangAttribute} id={headingId}>
                {title}
              </Heading>
            </InternalLinkOverlay>
            <Text fontSize="sm" lang={publicationLangAttribute}>
              {recommendation.publication.author}
            </Text>
          </Flex>
          <Box
            gridColumn="1/3"
            gridRow="2/3"
            background={palette.colors.card.css.background}
            borderRadius=".5rem"
            zIndex="-1"
          />
        </Grid>
      </LinkBox>
      <CreatedByTag createdBy={recommendation.createdBy} />
      <Edit doc={recommendation} />
    </Stack>
  );
};

export default RecommendationCard;
