import * as React from "react";
import { SVGProps } from "react";

const QuoteIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="60" height="44" viewBox="0 0 43 33" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M41.2871 0.275391V2.66797C37.9017 3.90495 35.2487 5.85807 33.3281 8.52734C31.4076 11.1641 30.4473 13.9147 30.4473 16.7793C30.4473 17.3978 30.5449 17.8535 30.7402 18.1465C30.8704 18.3418 31.0169 18.4395 31.1797 18.4395C31.3424 18.4395 31.5866 18.3255 31.9121 18.0977C32.9538 17.349 34.2721 16.9746 35.8672 16.9746C37.7227 16.9746 39.3665 17.7396 40.7988 19.2695C42.2311 20.7669 42.9473 22.5573 42.9473 24.6406C42.9473 26.8216 42.1172 28.7422 40.457 30.4023C38.8294 32.0625 36.8438 32.8926 34.5 32.8926C31.7656 32.8926 29.4056 31.7695 27.4199 29.5234C25.4342 27.2773 24.4414 24.2663 24.4414 20.4902C24.4414 16.0957 25.7923 12.1569 28.4941 8.67383C31.196 5.19076 35.4603 2.39128 41.2871 0.275391ZM17.8008 0.275391V2.66797C14.4154 3.90495 11.7624 5.85807 9.8418 8.52734C7.92122 11.1641 6.96094 13.9147 6.96094 16.7793C6.96094 17.3978 7.05859 17.8535 7.25391 18.1465C7.38411 18.3418 7.5306 18.4395 7.69336 18.4395C7.85612 18.4395 8.10026 18.3255 8.42578 18.0977C9.46745 17.349 10.7858 16.9746 12.3809 16.9746C14.2363 16.9746 15.8802 17.7396 17.3125 19.2695C18.7448 20.7669 19.4609 22.5573 19.4609 24.6406C19.4609 26.8216 18.6309 28.7422 16.9707 30.4023C15.3431 32.0625 13.3574 32.8926 11.0137 32.8926C8.2793 32.8926 5.91927 31.7695 3.93359 29.5234C1.94792 27.2773 0.955078 24.2663 0.955078 20.4902C0.955078 16.0957 2.30599 12.1569 5.00781 8.67383C7.70964 5.19076 11.974 2.39128 17.8008 0.275391Z"
      fill="currentColor"
    />
  </svg>
);

export default QuoteIcon;
